<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Alert
        <div class="card-header-actions">
          <a class="card-header-action" href="alert" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <b-form-group id="titleInputGroup1"
                              label="Title"
                              label-for="title">
                  <b-form-input id="title"
                              type="text"
                              v-model.lazy.trim="$v.form.title.$model"
                              :state="chkState('title')"
                              aria-describedby="titleFeedback"
                              placeholder="Please Enter Title"
                              autofocus />
                  <b-form-invalid-feedback id="titleFeedback">
                    <span v-if="!$v.form.title.required">- Title tidak boleh kosong! </span>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="text"
                            label="Text"
                            label-for="text">
                <b-form-textarea
                  id="text"
                  v-model.lazy.trim="$v.form.text.$model"
                  :state="chkState('text')"
                  placeholder="Enter Text notification ..."
                  aria-describedby="textFeedback"
                  rows="8"
                ></b-form-textarea>
                <b-form-invalid-feedback id="textFeedback">
                  <!-- <span v-if="!$v.form.content.maxLength">- Jumlah karakter kontent notifikasi tidak boleh lebih dari 191 karakter!</span> -->
                  <span v-if="!$v.form.text.required">- Text tidak boleh kosong! </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="volatility"
                label="Volatilitas"
                label-for="volatility"
              >
                <b-form-input id="volatility"
                            type="number"
                            v-model.lazy.trim="$v.form.volatility.$model"
                            :state="chkState('volatility')"
                            aria-describedby="volatilityFeedback"
                            placeholder="0"
                            autocomplete='given-name'
                            autofocus />
                <b-form-invalid-feedback id="volatilityFeedback" >
                  <span v-if="!$v.form.volatility.required">- Volatilitas tidak boleh kosong!</span><br>
                  <span v-if="!$v.form.volatility.decimal">- Volatilitas harus berupa angka valid.</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="type"
                label="Type"
                label-for="type">
                <b-form-select id="type"
                  :plain="true"
                  v-model.lazy.trim="$v.form.type.$model"
                  :state="chkState('type')"
                  aria-describedby="typeFeedBack"
                  autocomplete='given-name'
                  autofocus
                  :options="[{value: '', text:'Pilih Tipe'},
                            {value: 'idr', text: 'IDR'},
                            {value: 'gram', text: 'Gram'}]">
                </b-form-select>
                <b-form-invalid-feedback id="typeFeedBack">
                  <span v-if="!$v.form.type.required">- Tipe tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, helpers } from "vuelidate/lib/validators"
const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?$/)

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        text: '',
        volatility: '',
        type: '',
        title: ''
      },
      status: '',
      origin: '',
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          image: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      text: {
        required
      },
      volatility: {
        required,
        decimal
      },
      type: {
        required
      },
      title: {
        required
      }
    }
  },
  created(){

    this.$http.get(`alert/` + this.$route.params.id)
    .then((result) => {
      this.form.text = result.data.data.text;
      this.form.volatility = result.data.data.volatility;
      this.form.type = result.data.data.type;
      this.form.title = result.data.data.title;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        const formData =  new FormData();
        formData.append('_method', 'PUT')
        formData.append('text', this.form.text)
        formData.append('volatility', this.form.volatility)
        formData.append('type', this.form.type)
        formData.append('title', this.form.title)
        this.$http.post(`alert/` + this.$route.params.id, formData)
        .then((result) => {
          this.isLoading = false
          this.$router.push("/alert");
          this.$toasted.success(result.data.meta.message)
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
            if(this.errors.status == 400) {
              this.$toasted.error('Please fill out the form that must be required')
              this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              )
            } else if (this.errors.status == 422) {
              this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              )
            }
          }
        })
      }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
